import React from 'react';
export default function HelpIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#4D61FC" fillRule="evenodd">
        <path
          d="M7.755 10.726a.837.837 0 0 0-.828.838c0 .447.36.84.828.84a.846.846 0 0 0 .839-.84.846.846 0 0 0-.84-.838z"
          fillRule="nonzero"
        />
        <path d="M5.183 6.437c0-.34.11-.685.328-1.034.218-.35.537-.638.956-.868.419-.229.908-.343 1.466-.343.52 0 .978.095 1.375.287.398.192.705.452.922.782.216.329.324.687.324 1.074 0 .304-.061.57-.185.8a2.45 2.45 0 0 1-.44.594c-.17.166-.476.446-.916.84-.122.111-.22.209-.293.293a1.19 1.19 0 0 0-.164.23c-.035.07-.063.14-.083.21-.02.07-.049.193-.088.368-.068.373-.282.559-.64.559a.653.653 0 0 1-.47-.183c-.127-.122-.19-.302-.19-.542 0-.301.046-.562.14-.782.093-.22.216-.413.37-.58.154-.166.362-.364.623-.593.23-.2.395-.352.497-.454a1.54 1.54 0 0 0 .258-.341.825.825 0 0 0 .104-.408.95.95 0 0 0-.32-.726c-.212-.197-.487-.295-.824-.295-.393 0-.684.1-.87.298-.186.199-.343.492-.472.878-.122.405-.353.607-.693.607-.2 0-.37-.07-.508-.212a.649.649 0 0 1-.207-.46zm2.621 5.887a.845.845 0 0 1-.572-.213c-.162-.141-.244-.339-.244-.593a.798.798 0 0 1 .816-.8c.226 0 .416.077.57.23a.774.774 0 0 1 .23.57c0 .25-.08.447-.24.59a.816.816 0 0 1-.56.216z" />
        <path
          d="M8 0C3.579 0 0 3.578 0 8v7.48c0 .345.175.52.52.52H8c4.421 0 8-3.578 8-8 0-4.421-3.578-8-8-8zm0 14.75H1.25V8c0-3.73 3.019-6.75 6.75-6.75 3.73 0 6.75 3.019 6.75 6.75 0 3.73-3.019 6.75-6.75 6.75z"
          fillOpacity=".3"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
