import React from 'react';
export default function CloseIcon(props) {
  return (
    <svg
      id="Layer_1"
      {...props}
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st0"
          d="M256,0c141.4,0,256,114.6,256,256S397.4,512,256,512S0,397.4,0,256S114.6,0,256,0z M256,35.7&#xA;&#x9;&#x9;C134.3,35.7,35.7,134.3,35.7,256S134.3,476.3,256,476.3S476.3,377.7,476.3,256S377.7,35.7,256,35.7z M312.5,175&#xA;&#x9;&#x9;c6.8-6.8,17.9-6.8,24.7,0c6.8,6.8,6.8,17.9,0,24.7L281,255.9l56.2,56.1c6.8,6.8,6.8,17.9,0,24.7c-6.8,6.8-17.9,6.8-24.7,0&#xA;&#x9;&#x9;l-56.2-56.2l-56.2,56.2c-6.1,6.1-15.7,6.7-22.5,1.8l-2.2-1.8c-6.8-6.8-6.8-17.9,0-24.7l56.2-56.1l-56.2-56.1&#xA;&#x9;&#x9;c-6.8-6.8-6.8-17.9,0-24.7c6.8-6.8,17.9-6.8,24.7,0l56.2,56.2L312.5,175z"
        />
      </g>
    </svg>
  );
}
