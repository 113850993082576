import { Input, message, Modal } from 'antd';
import React, { FC } from 'react';
import { SocialMediaIcons } from '../SocialMediaShare/SocialMediaShare';
import ReactGA from 'react-ga4';
import CopyIcon from '../../images/copy-icon-25.png';

interface SharedQueriesProps {
  shareQuery: any;
  showModal: any;
  setShowModal: any;
  setShareQueryName?: any;
}

const ShareQuery: FC<SharedQueriesProps> = ({
  shareQuery,
  setShareQueryName,
  showModal,
  setShowModal,
}) => {
  const onCopy = () => {
    navigator.clipboard.writeText(
      `${window.location.host}/query?name=${shareQuery?.id}`
    );
    message.success('Link copied to clipboard');
    ReactGA.event(
      {
        category: 'Query',
        action: 'Copy Query',
      },
      `${window.location.host}/query?name=${shareQuery?.id}`
    );
  };

  return (
    <Modal
      title={'Share Query'}
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
        setShareQueryName('');
      }}
      style={{ textAlign: 'center' }}
      footer={null}
    >
      <div style={{ padding: '0.5rem' }}>
        <div>Click on the buttons below to share this query</div>
        <div
          style={{
            paddingRight: '.5rem',
            display: 'flex',
            margin: '0.5rem',
          }}
        >
          <Input
            disabled
            value={`${window.location.host}/query?name=${shareQuery?.id}`}
          />

          <img
            src={CopyIcon}
            alt={'Copy'}
            style={{ marginLeft: '0.5rem' }}
            className={'cursor-pointer'}
            height={30}
            onClick={onCopy}
          />
        </div>
        <div>
          <div>Total Likes: {shareQuery?.likes}</div>
          <div>Total Searches: {shareQuery?.searchCount}</div>
        </div>
        <SocialMediaIcons
          title={
            'I have created an amazing query to find the results I was looking for. Check it out'
          }
          nestedURL={`/query?name=${shareQuery?.id}`}
        />
      </div>
    </Modal>
  );
};

export default ShareQuery;
