import clsx from 'clsx';
import { FC } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import HorizontalCard, { COLOR } from '../HorizontalCard/HorizontalCard';
import styles from './SocialMediaShare.module.css';
import ReactGA from 'react-ga4';

interface SocialMediaShareProps {}

export const SocialMediaIcons = ({
  nestedURL,
  title,
}: {
  nestedURL?: string;
  title: string;
}) => {
  const url =
    'https://hiring-search.careerflow.ai/' + (nestedURL ? nestedURL : '');

  const analytics = (socialMedia: string) => {
    ReactGA.event({ category: 'Share', action: socialMedia });
  };
  return (
    <>
      <FacebookShareButton
        onClick={() => analytics('Facebook Share')}
        url={url}
        title={title}
        hashtag={'#Careerflow'}
        className={clsx(styles.share_icons, styles.fbBtn, 'btn-animation')}
      >
        <FacebookIcon size={32} round /> Share
      </FacebookShareButton>
      <TwitterShareButton
        onClick={() => analytics('Twitter Share')}
        url={url}
        title={title}
        hashtags={['FAANG', 'recruiting', 'hiring']}
        className={clsx(styles.share_icons, styles.twitterBtn, 'btn-animation')}
      >
        <TwitterIcon size={32} round /> Tweet
      </TwitterShareButton>
      <WhatsappShareButton
        onClick={() => analytics('WhatsApp Share')}
        url={url}
        title={title}
        className={clsx(
          styles.share_icons,
          styles.whatsappBtn,
          'btn-animation'
        )}
      >
        <WhatsappIcon size={32} round /> Message
      </WhatsappShareButton>
      <LinkedinShareButton
        onClick={() => analytics('LinkedIn Share')}
        title={title}
        url={url}
        className={clsx(
          styles.share_icons,
          styles.linkedinBtn,
          'btn-animation'
        )}
      >
        <LinkedinIcon size={32} round /> Share
      </LinkedinShareButton>
    </>
  );
};

const SocialMediaShare: FC<SocialMediaShareProps> = () => (
  <HorizontalCard header={'Like this tool? Share'} color={COLOR.pink}>
    <SocialMediaIcons
      title={
        'I came across this tool which lets you search for recruiters and hiring managers easily using advanced Google search. It’s much better than searching directly on LinkedIn!'
      }
    />
  </HorizontalCard>
);

export default SocialMediaShare;
