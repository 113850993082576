import React, { FC } from 'react';
import './HorizontalCard.css';

export enum COLOR {
  red = 'red',
  blue = 'blue',
  green = 'green',
  pink = 'pink',
  orange = 'orange',
}
interface HorizontalCardProps {
  header: string;
  content?: React.ReactNode;
  color: COLOR;
  children?: React.ReactNode;
}

const HorizontalCard: FC<HorizontalCardProps> = ({
  header,
  content,
  children,
  color = 'red',
}) => (
  <div className={`HorizontalCard ${color}`}>
    <div style={{ fontSize: '24px', fontWeight: 600, paddingBottom: '1rem' }}>
      {header}
    </div>
    {content || children}
  </div>
);

export default HorizontalCard;
