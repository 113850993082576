import { Spin } from 'antd';
import { getDownloadURL, ref } from 'firebase/storage';
import { useContext, useEffect, useState } from 'react';
import { AppContext, InitialState } from '../../auth';
import GooggleIcon from '../../images/goggleicon.png';
import { logout, storage } from '../../utils/firebase/firebaseIndex';
import styles from './ProfileInfo.module.css';

const ProfileInfo = () => {
  const { userInfo, isLoggedIn, signInWithGoogleCall } = useContext(
    AppContext
  ) as InitialState;

  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [profilePic, setProfilePic] = useState('');

  const getDownloadUrl = (profilePath: string) => {
    if (profilePath !== '' || profilePath !== null) {
      setTimeout(() => {
        const storageRef = ref(storage, profilePath);
        getDownloadURL(storageRef)
          .then((url: any) => setProfilePic(url))
          .catch((err) => console.log(err));
      }, 1000);
    }
  };

  useEffect(() => {
    if (userInfo.profilePic && userInfo.profilePic !== '' && !isPhotoLoading) {
      setIsPhotoLoading(true);
      getDownloadUrl(userInfo.profilePic);
    }
  }, [userInfo, isPhotoLoading]);

  return (
    <div>
      {isLoggedIn && profilePic !== '' ? (
        <li className="nav-02__item">
          <button className="button button--black-outline button--empty button--has-dropdown button--has-arrow js-toggle-dropdown">
            <span>
              <img
                src={profilePic}
                alt="img"
                height="40"
                width="40"
                className={styles.avatar}
              />
            </span>
            <span className="dropdown">
              <ul className="dropdown__list">
                <li className="dropdown__item">
                  <span className="button button--empty button--black-outline">
                    <div className="button__text">{userInfo.username}</div>
                  </span>
                  <a
                    href="/"
                    className="button button--empty button--black-outline"
                  >
                    <div
                      className="button__text"
                      style={{ color: '#518ce8' }}
                      onClick={logout}
                    >
                      Logout
                    </div>
                  </a>
                </li>
              </ul>
            </span>
          </button>
        </li>
      ) : profilePic === '' && isLoggedIn ? (
        <Spin style={{ marginRight: '2rem' }} />
      ) : (
        <div
          style={{ fontSize: '14px' }}
          className="button button--empty"
          onClick={signInWithGoogleCall}
        >
          <img
            className="img-icon"
            src={GooggleIcon}
            alt="google-login"
            style={{
              height: '20px',
              width: '20px',
              marginRight: '2px',
            }}
          />
          <strong>Sign In</strong>
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
