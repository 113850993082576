let innerConfig = {
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
};

export const config = innerConfig;

export const saveQueryApiPath = `${config.baseUrl}/saveQuery`;
export const saveAllQueriesPath = `${config.baseUrl}/queries`;
export const userRoleApiPath = `${config.baseUrl}/user-role`;
export const userDetailApiPath = `${config.baseUrl}/userdetail`;
export const shareQueryApiPath = `${config.baseUrl}/shareQuery`;
export const likeApiPath = `${config.baseUrl}/like`;
export const queryFeedbackApiPath = `${config.baseUrl}/queryFeedback`;
