import { FC } from 'react';
import ProfileInfo from '../ProfileInfo/ProfileInfo';

interface OperatorProps {}

const Topnav: FC<OperatorProps> = () => {
  return (
    <>
      <nav className="js-nav nav-02 nav-02--static">
        <div className="container container--large">
          <div className="nav-02__box">
            <div className="nav-02__logo">
              <a
                className="nav-02__link"
                href="https://careerflow.ai/"
                target="_self"
              >
                <img
                  loading="lazy"
                  className="nav-02__logo_img"
                  src="static/img/Careerflow-logo.png"
                  height={47}
                  alt="FAANGPath"
                />
                <span className="nav-02__logo_text">
                  {process.env.REACT_APP_APP_NAME} | X-ray search
                </span>
              </a>
            </div>
            <div className="nav-02__links js-menu">
              <div className="nav-02__list_wrapper">
                <ul className="nav-02__list nav-02__list--desktop">
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://faangpath.com/success-stories/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">Success Stories</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <button className="button button--black-outline button--empty button--has-dropdown button--has-arrow js-toggle-dropdown">
                      <span className="button__text">Services</span>
                      <span className="dropdown">
                        <ul className="dropdown__list">
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/pricing/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">All Services</span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/resume-review/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                Resume Review
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/mock-interview/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                Mock Interview
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/resume-writing/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                Resume Writing
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/linkedin-makeover/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                LinkedIn Makeover
                              </span>
                            </a>
                          </li>
                        </ul>
                      </span>
                    </button>
                  </li>
                  <li className="nav-02__item">
                    <button className="button button--black-outline button--empty button--has-dropdown button--has-arrow js-toggle-dropdown">
                      <span className="button__text">Free Resources</span>
                      <span className="dropdown">
                        <ul className="dropdown__list">
                          <li className="dropdown__item">
                            <a
                              href="https://www.careerflow.ai/blog"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">Blog</span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/resources"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                Job Search Tools
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://discord.com/invite/WWbjEaZ"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">Community</span>
                            </a>
                          </li>
                        </ul>
                      </span>
                    </button>
                  </li>
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://faangpath.com/faq"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">FAQ</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://faangpath.com/mentors"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">Mentors</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://www.careerflow.ai/contact"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">Contact</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <ProfileInfo />
                  </li>
                  <li className="nav-02__item" />
                </ul>
                <ul className="nav-02__list nav-02__list--mobile">
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://faangpath.com/success-stories/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">Success Stories</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <button className="button button--black-outline button--empty button--has-dropdown button--has-arrow js-toggle-dropdown">
                      <span className="button__text">Services</span>
                      <span className="dropdown">
                        <ul className="dropdown__list">
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/pricing/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">All Services</span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/resume-review/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                Resume Review
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/mock-interview/"
                              target="_self"
                              className="button button--empty button--black-outline"
                            >
                              <span className="button__text">
                                Mock Interview
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/resume-writing/"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                Resume Writing
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/linkedin-makeover"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                LinkedIn Makeover
                              </span>
                            </a>
                          </li>
                        </ul>
                      </span>
                    </button>
                  </li>
                  <li className="nav-02__item">
                    <button className="button button--black-outline button--empty button--has-dropdown button--has-arrow js-toggle-dropdown">
                      <span className="button__text">Free Resources</span>
                      <span className="dropdown">
                        <ul className="dropdown__list">
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/blog"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">Blog</span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://faangpath.com/resources"
                              target="_blank"
                              className="button button--empty button--black-outline"
                              rel="noreferrer"
                            >
                              <span className="button__text">
                                Job Search Tools
                              </span>
                            </a>
                          </li>
                          <li className="dropdown__item">
                            <a
                              href="https://discord.com/invite/WWbjEaZ"
                              target="_self"
                              className="button button--empty button--black-outline"
                            >
                              <span className="button__text">Community</span>
                            </a>
                          </li>
                        </ul>
                      </span>
                    </button>
                  </li>
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://faangpath.com/faq"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">FAQ</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://faangpath.com/mentors"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">Mentors</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <a
                      className="button button--black-outline button--empty"
                      href="https://faangpath.com/contact"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="button__text">Contact</span>
                    </a>
                  </li>
                  <li className="nav-02__item">
                    <ProfileInfo />
                  </li>
                  <li className="nav-02__item" />
                </ul>
              </div>
              <div className="nav-02__burger">
                <button
                  className="burger burger--black js-open-menu"
                  type="button"
                >
                  <div className="burger__box">
                    <div className="burger__inner" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="top-description">
        <h1 className="heading text-white">
          Search for hiring managers and recruiters
        </h1>
        Are you looking for recruiters, hiring managers, team leads, etc. from
        your dream companies who are hiring but not sure how to find them?
        Let&nbsp;{process.env.REACT_APP_APP_NAME} | X-ray search - our recruiter
        search tool, be your job search best friend!
      </div>
    </>
  );
};

export default Topnav;
