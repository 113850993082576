import { Button, message, Modal, Spin, Tooltip } from 'antd';
import axios from 'axios';
import { FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AppContext, InitialState } from '../../auth/index';
import CloseIcon from '../../images/CloseIcon.jsx';
import ShareIcon from '../../images/Share.jsx';
import {
  likeApiPath,
  saveAllQueriesPath,
  saveQueryApiPath,
} from '../../utils/apiPaths';
import { auth } from '../../utils/firebase/firebaseIndex';
import HorizontalCard, { COLOR } from '../HorizontalCard/HorizontalCard';
import Like from '../Like/Like';
import styles from './SavedQueries.module.css';
import ReactGA from 'react-ga4';
import ShareQuery from '../ShareQuery/ShareQuery';
interface SavedQueriesProps {
  savedQueries: Array<any>;
  setSavedQueries: SetStateAction<any>;
  savedQueryLoading: boolean;
}

const SavedQueries: FC<SavedQueriesProps> = ({
  savedQueries,
  setSavedQueries,
  savedQueryLoading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { signInWithGoogleCall } = useContext(AppContext) as InitialState;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userLikedQueries, setUserLikedQueries] = useState<Array<string>>();
  const [user, loading] = useAuthState(auth);
  const [selectedQuery, setSelectedQuery] = useState<any>();
  const removeQuery = (queryName: string) => {
    const updatedSavedSearches = savedQueries.filter(
      (query: any) => query.queryName !== queryName
    );
    axios
      .delete(saveQueryApiPath + `?queryId=${queryName}`)
      .then(() => message.success('Query Deleted Successfully'));
    setSavedQueries(updatedSavedSearches);
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (!user) return;
    axios
      .get(likeApiPath)
      .then((res) =>
        setUserLikedQueries(res.data?.map((likeObj: any) => likeObj.id))
      )
      .catch((e) => {
        console.log('error is getting query likes:', e);
        // message.error(
        //     'There was problem in getting like status of queries'
        // );
      });
  }, [user]);

  const closeTooltipText = <span>Delete query</span>;

  const shareTooltipText = <span>Share query</span>;

  const saveQueryApiCall = async (
    googleSearchQuery: any,
    hashedId: string,
    queryParams: any
  ) => {
    await axios.post(saveAllQueriesPath, {
      id: hashedId,
      queryString: googleSearchQuery,
      queryFields: queryParams,
    });
  };

  if (savedQueryLoading) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  } else
    return (
      <div className={styles.SavedQueries}>
        <HorizontalCard header={'Saved Searches'} color={COLOR.green}>
          {savedQueries.length && userLikedQueries ? (
            savedQueries?.map((query: any) => (
              <div className={styles.savedQuery} key={query.queryName}>
                <Like
                  query={query}
                  likeStatus={userLikedQueries.includes(query.queryName)}
                />
                <div
                  className={styles.queryTitle}
                  onClick={() => {
                    window.open(query.queryString);
                    saveQueryApiCall(
                      query.queryString,
                      query.hashString,
                      query.queryFields
                    );
                  }}
                >
                  {query.queryName}
                </div>
                <span>
                  <Tooltip placement="top" title={shareTooltipText}>
                    <ShareIcon
                      width={18}
                      height={18}
                      className="btn-animation"
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        ReactGA.event({
                          category: 'Query',
                          action: 'Share Query Click',
                        });
                        setSelectedQuery(query);
                        setShowModal(true);
                      }}
                    />
                  </Tooltip>
                </span>

                <span className={styles.close_icon}>
                  <Tooltip placement="top" title={closeTooltipText}>
                    <CloseIcon
                      width={18}
                      height={18}
                      fill="rgba(0, 146, 0, 0.7)"
                      className="btn-animation"
                      onClick={() => {
                        setSelectedQuery(query);
                        setShowConfirmModal(true);
                      }}
                    />
                  </Tooltip>
                </span>
              </div>
            ))
          ) : !!user ? (
            'Once you save your queries they will show up here!'
          ) : loading ? (
            <Spin />
          ) : (
            <div>
              <span
                onClick={() => signInWithGoogleCall()}
                style={{
                  textDecoration: 'underline',
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                Login
              </span>{' '}
              to load saved queries
            </div>
          )}
        </HorizontalCard>
        <Modal
          title={'Share Query'}
          visible={showConfirmModal}
          onCancel={() => setShowConfirmModal(false)}
          style={{ textAlign: 'center' }}
          footer={null}
        >
          <div>
            Are you sure you want to delete?
            <div>
              <Button onClick={() => removeQuery(selectedQuery?.id)}>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
        <ShareQuery
          shareQuery={selectedQuery}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </div>
    );
};
export default SavedQueries;
