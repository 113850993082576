import React from 'react';

function ShareIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
    >
      <path
        fill="none"
        stroke="rgba(0, 146, 0, 0.7)"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        d="M44 32v12H6V4"
      ></path>
      <path
        fill="none"
        stroke="rgba(0, 146, 0, 0.7)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        d="M36 19l6-6-6-6"
      ></path>
      <path
        fill="none"
        stroke="rgba(0, 146, 0, 0.7)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        d="M42 13h-6c-19.689-.49-22.333 6.167-22 23"
      ></path>
    </svg>
  );
}

export default React.memo(ShareIcon);
