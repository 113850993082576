import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './auth/axios';
import Main from './components/Main/Main';
function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');
  }, []);
  return (
    <div className="App">
      <ErrorBoundary>
        <Router>
          <Main />
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;
