import alphaSort from 'alpha-sort';

export const addOROperator = (values: Array<String>) => {
  let resultString = '';
  for (const value of values) {
    resultString = resultString + ' OR ' + value;
  }
  return resultString;
};

export const addANDOperator = (values: Array<String>) => {
  let resultString = '';
  for (const value of values) {
    resultString = resultString + ' AND ' + value;
  }
  return resultString;
};

export const addPlusOperator = (values: Array<String>) => {
  let resultString = '';
  for (const value of values) {
    if (value !== undefined) resultString = `${resultString} +"${value}" `;
  }
  return resultString;
};

export const addMinusOperator = (keywords: Array<String>) => {
  let resultString = '';
  for (const value of keywords) {
    if (value !== undefined) resultString = `${resultString} -"${value}" `;
  }
  return resultString;
};

const _ = (arr: Array<string>): Array<string> => {
  const ar = [...arr];

  // adding operators to the string values to form "operator prefixed string"
  // for example Nodejs OR Reactjs AND Bootstrap will be converted to 'Nodejs' 'OR Reactjs' 'AND Bootstrap'
  for (var i = 0; i < ar.length; i++) {
    if (ar[i] === 'AND' || ar[i] === 'OR') {
      ar[i + 1] = ar[i] + ' ' + ar[i + 1];
      ar.splice(i, 1);
    }
  }
  // alphaSort() will sort Operator prefixed string alphabetically.
  return ar.sort(alphaSort());
};

export const createHashedString = (queryString: any) => {
  const queryString2 = { ...queryString };
  const {
    companyTags: company,
    skillTags: skills,
    excludeTags: excludedKeywords,
    includeTags: includedKeywords,
    role,
  } = queryString2;
  const finalString = [
    _(company),
    _(skills),
    _(excludedKeywords),
    _(includedKeywords),
    _(role),
  ];
  const final = btoa(JSON.stringify(finalString));
  return final;
};

export const generateQuery = (queryParams: any) => {
  const {
    companyTags: company,
    skillTags: skills,
    excludeTags: excludedKeywords,
    includeTags: includedKeywords,
    role,
    exactSearchRole,
  } = queryParams;
  let companyQuery = '';
  let skillQuery = '';
  let excludeQuery = '';
  let includeQuery = '';
  let roleQuery = '';
  for (const val of company) {
    if (val === 'OR') companyQuery = companyQuery + ` ${val} `;
    else companyQuery = companyQuery + `intitle:"${val}"`;
  }

  for (const val of skills) {
    if (val === 'OR' || val === 'AND') skillQuery = skillQuery + ` ${val}`;
    else skillQuery = skillQuery + ` "${val}"`;
  }

  for (const val of excludedKeywords) {
    if (val === 'OR' || val === 'AND') excludeQuery = excludeQuery + ` ${val}`;
    else excludeQuery = excludeQuery + ` -"${val}"`;
  }

  for (const val of includedKeywords) {
    if (val === 'OR' || val === 'AND') includeQuery = includeQuery + ` ${val}`;
    else includeQuery = includeQuery + ` "${val}"`;
  }

  for (const val of role) {
    if (val === 'OR' || val === 'AND') roleQuery = roleQuery + ` ${val}`;
    else {
      if (exactSearchRole) roleQuery = roleQuery + ` "Hiring * ${val}"`;
      else roleQuery = roleQuery + ` Hiring ${val}`;
    }
  }

  return `${companyQuery}${skillQuery}${excludeQuery}${includeQuery}${roleQuery}`;
};
