import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Space,
  Tooltip,
} from 'antd';
import axios from 'axios';
import clsx from 'clsx';
import React, { FC, SetStateAction, useContext, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../..';
import { AppContext, InitialState } from '../../auth';
import { sampleQueries } from '../../constants/sampleQueries';
import CloseIcon from '../../images/CloseIcon.jsx';
import HelpIcon from '../../images/help.jsx';
import {
  queryFeedbackApiPath,
  saveAllQueriesPath,
  saveQueryApiPath,
  shareQueryApiPath,
} from '../../utils/apiPaths';
import { auth } from '../../utils/firebase/firebaseIndex';
import { createHashedString, generateQuery } from '../../utils/queryMethods';
import CountriesDropdown from '../CountriesDropdown/CountriesDropdown';
import Like from '../Like/Like';
import ShareQuery from '../ShareQuery/ShareQuery';
import styles from './InputForm.module.css';
interface InputFormProps {
  setSavedQueries: SetStateAction<any>;
  savedQueries: Array<any>;
  savedQueryLoading: boolean;
}
const InputForm: FC<InputFormProps> = ({
  setSavedQueries,
  savedQueries,
  savedQueryLoading,
}) => {
  const [companyTags, setCompanyTags] = useState<Array<string>>([]);
  const [skillTags, setSkillTags] = useState<Array<string>>([]);
  const [excludeTags, setExcludeTags] = useState<Array<string>>([]);
  const [includeTags, setIncludeTags] = useState<Array<string>>([]);
  const [roleTags, setRoleTags] = useState<Array<string>>([]);
  const [country, setCountry] = useState();
  const [sharedQuery, setSharedQuery] = useState<any>();
  const [companyInputTagValue, setCompanyInputTagValue] = useState('');
  const [intialMessageDelivered, setIntialMessageDelivered] = useState(false);
  const [skillInputTagValue, setSkillInputTagValue] = useState('');
  const [excludeInputTagValue, setExcludeInputTagValue] = useState('');
  const [includeInputTagValue, setIncludeInputTagValue] = useState('');
  const [roleInputTagValue, setRoleInputTagValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [didNotLikeQueryModal, setDidNotLikeQueryModal] = useState(false);
  const [queryFeedback, setQueryFeedback] = useState('');
  const [queryFeedbackFlag, setQueryFeedbackFlag] = useState(null);
  const [queryName, setQueryName] = useState('');
  const [form] = Form.useForm();
  const [activeInput, setActiveInput] = useState('');
  const [user, loading] = useAuthState(auth);
  const [exactSearchRole, setExactSearchRole] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const orOperator = 'OR';
  const andOperator = 'AND';
  const { signInWithGoogleCall, isLoggedIn } = useContext(
    AppContext
  ) as InitialState;

  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      if (window.location.pathname !== '/query' && !user) return;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const { name: queryName } = Object.fromEntries(urlSearchParams.entries());
      const { data: query } = await axios.get(saveQueryApiPath, {
        params: { queryId: queryName },
      });
      if (!query) {
        message.error('The URL or Query is Invalid or no longer exists');
        navigate('/');
        return;
      }
      setSharedQuery(query);
      if (query && query.queryFields) {
        setCompanyTags(query.queryFields.companyTags);
        setIncludeTags(query.queryFields.includeTags);
        setExcludeTags(query.queryFields.excludeTags);
        setCountry(query.queryFields.country);
        setSkillTags(query.queryFields.skillTags);
        setRoleTags(query.queryFields.role);
        setExactSearchRole(
          query.exactSearchRole === undefined ? false : query.exactSearchRole
        );
      }
    })();
  }, [loading, user, navigate]);

  const handleSkillTagInputChange = (e: any) => {
    setSkillInputTagValue(e.target.value);
  };

  const removeTagOnEmptyBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    tagArray: Array<String>,
    setTagFn: React.SetStateAction<any>,
    onTagConfirm: Function
  ) => {
    if (e.key === 'Backspace' && !e.currentTarget.value.length) {
      const tags = [...tagArray];
      tags.pop();
      if (tags[tags.length - 1] === 'OR' || tags[tags.length - 1] === 'AND')
        tags.pop();
      setTagFn(tags);
    }
    if (e.key === ',') {
      e.preventDefault();
      onTagConfirm();
    }
  };

  const handleExcludeTagInputConfirm = () => {
    if (
      excludeInputTagValue &&
      excludeTags.indexOf(skillInputTagValue) === -1 &&
      excludeInputTagValue.trim().length !== 0
    ) {
      if (excludeTags.length === 0)
        setExcludeTags((tag) => [...tag, excludeInputTagValue]);
      else setExcludeTags((tag) => [...tag, orOperator, excludeInputTagValue]);
    }
    setExcludeInputTagValue('');
  };

  const handleIncludeTagInputConfirm = () => {
    if (
      includeInputTagValue &&
      includeTags.indexOf(includeInputTagValue) === -1 &&
      includeInputTagValue.trim().length !== 0
    ) {
      if (includeTags.length === 0)
        setIncludeTags((tag) => [...tag, includeInputTagValue]);
      else setIncludeTags((tag) => [...tag, orOperator, includeInputTagValue]);
    }
    setIncludeInputTagValue('');
  };

  const handleRoleTagInputConfirm = () => {
    if (
      roleInputTagValue &&
      roleTags.indexOf(roleInputTagValue) === -1 &&
      roleInputTagValue.trim().length !== 0
    ) {
      if (roleTags.length === 0)
        setRoleTags((tag) => [...tag, roleInputTagValue]);
      else setRoleTags((tag) => [...tag, orOperator, roleInputTagValue]);
    }
    setRoleInputTagValue('');
  };

  const handleSkillTagInputConfirm = () => {
    if (
      skillInputTagValue &&
      skillTags.indexOf(skillInputTagValue) === -1 &&
      skillInputTagValue.trim().length !== 0
    ) {
      if (skillTags.length === 0)
        setSkillTags((tag) => [...tag, skillInputTagValue]);
      else setSkillTags((tag) => [...tag, orOperator, skillInputTagValue]);
    }
    setSkillInputTagValue('');
  };
  const handleCompanyTagInputChange = (e: any) => {
    setCompanyInputTagValue(e.target.value);
  };

  const handleExcludeTagInputChange = (e: any) => {
    setExcludeInputTagValue(e.target.value);
  };
  const handleIncludeTagInputChange = (e: any) => {
    setIncludeInputTagValue(e.target.value);
  };
  const handleRoleTagInputChange = (e: any) => {
    setRoleInputTagValue(e.target.value);
  };
  const handleCompanyTagInputConfirm = () => {
    if (
      companyInputTagValue &&
      companyTags.indexOf(companyInputTagValue) === -1 &&
      companyInputTagValue.trim().length !== 0
    ) {
      if (companyTags.length === 0)
        setCompanyTags((tag) => [...tag, companyInputTagValue]);
      else setCompanyTags((tag) => [...tag, orOperator, companyInputTagValue]);
    }
    setCompanyInputTagValue('');
  };

  const getQueryParams = () => {
    return {
      companyTags: companyInputTagValue.length
        ? companyTags.length
          ? [...companyTags, 'OR', companyInputTagValue]
          : [companyInputTagValue]
        : companyTags,
      skillTags: skillInputTagValue.length
        ? excludeTags.length
          ? [...skillTags, 'OR', skillInputTagValue]
          : [skillInputTagValue]
        : skillTags,
      excludeTags: excludeInputTagValue.length
        ? excludeTags.length
          ? [...excludeTags, 'OR', excludeInputTagValue]
          : [excludeInputTagValue]
        : excludeTags,
      includeTags: includeInputTagValue.length
        ? includeTags.length
          ? [...includeTags, 'OR', includeInputTagValue]
          : [includeInputTagValue]
        : includeTags,
      role: roleInputTagValue.length
        ? roleTags.length
          ? [...roleTags, 'OR', roleInputTagValue]
          : [roleInputTagValue]
        : roleTags,
      country,
      exactSearchRole: exactSearchRole,
    };
  };

  const onFeedbackFlagChange = (e: any) => {
    setQueryFeedbackFlag(e.target.value);
  };

  const onFinish = () => {
    const queryParams = getQueryParams();
    handleSkillTagInputConfirm();
    handleRoleTagInputConfirm();
    handleExcludeTagInputConfirm();
    handleIncludeTagInputConfirm();
    handleCompanyTagInputConfirm();

    ReactGA.event({ category: 'Query', action: 'Search Query' }, queryParams);
    const generatedQuery = generateQuery(queryParams);

    const hashedId = createHashedString(queryParams);
    if (sharedQuery?.hashString !== hashedId) navigate('/');
    if (window.location.pathname === '/query') {
      axios.post(shareQueryApiPath, { id: sharedQuery?.id });
    }
    setShowModal(true);
    const googleSearchQuery = `https://www.google.com/search?q="Hiring"+${encodeURIComponent(
      generatedQuery
    )} -intitle:"profiles" -inurl:"dir/+"+site:${
      country || 'www'
    }.linkedin.com/in+OR+site:${country || 'www'}.linkedin.com/pub`;

    window.open(googleSearchQuery);
    saveQueryApiCall(googleSearchQuery, hashedId, {
      ...queryParams,
      country,
    });
  };

  const checkQueryParamsEmpty = () => {
    const { country, ...queryParams } = getQueryParams();
    if (country) return false;
    for (const [value] of Object.entries(queryParams)) {
      if (value.length !== 0) return false;
    }
    return true;
  };

  const handleSaveQuery = async () => {
    navigate(``);
    ReactGA.event({
      category: 'Query',
      action: 'Save Query Button clicked',
    });
    await signInWithGoogleCall();
    setShowModal(true);
  };

  const onExactSearchChange = (e: { target: { checked: any } }) => {
    setExactSearchRole(e.target.checked);
  };
  const saveQuery = async () => {
    const queryParams = getQueryParams();
    const hashedId = createHashedString(queryParams);
    const generatedQuery = generateQuery(queryParams);
    ReactGA.event({ category: 'Query', action: 'Save Query' }, queryParams);
    const googleSearchQuery = `https://www.google.com/search?q="Hiring"+${generatedQuery} -intitle:"profiles" -inurl:"dir/+"+site:${
      country || 'www'
    }.linkedin.com/in+OR+site:${country || 'www'}.linkedin.com/pub`;
    const savedQuery: any[] = [];
    await signInWithGoogleCall();

    axios
      .post(saveQueryApiPath, {
        queryId: hashedId,
        queryString: googleSearchQuery,
        queryFields: queryParams,
        queryName: queryName,
        hashString: hashedId,
      })
      .then(() => {
        savedQuery.push({
          queryName: queryName,
          queryString: googleSearchQuery,
          id: queryName,
          likes: 0,
          searchCount: 1,
        });
        setSavedQueries([...savedQueries, ...savedQuery]);
      })
      .catch((err) => {
        if (err.response?.data?.message) {
          message.error(
            'Query with same name already exists, please choose another name'
          );
        } else {
          message.error(
            'There was a error in saving the query, please try in sometime'
          );
        }
      })
      .finally(() => {
        setShowModal(false);
        setShowShareModal(true);
        navigate(`/query?name=${queryName}`);
      });
  };

  const saveQueryApiCall = async (
    googleSearchQuery: any,
    hashedId: string,
    queryParams: any
  ) => {
    await axios.post(saveAllQueriesPath, {
      id: hashedId,
      queryString: googleSearchQuery,
      queryFields: queryParams,
    });
  };

  const feedbackApiCall = async (googleSearchQuery: any) => {
    setShowModal(false);
    await axios.post(queryFeedbackApiPath, {
      queryId: sharedQuery.id,
      feedbackFlag: queryFeedbackFlag,
      feedback: queryFeedback,
    });
  };

  const deleteTags = React.useCallback(
    (index: any, e: any, setTags: React.SetStateAction<any>) => {
      e.preventDefault();
      setTags((prevState: Array<any>) =>
        prevState.filter((tag, i) =>
          index === 0
            ? i !== index && i !== index + 1
            : i !== index && i !== index - 1
        )
      );
    },
    []
  );

  const switchTag = React.useCallback(
    (index: number, e: any, setTags: React.SetStateAction<any>) => {
      e.preventDefault();
      setTags((prevState: Array<any>) =>
        prevState.map((tag, i) =>
          i === index && tag === orOperator
            ? andOperator
            : i === index && tag === andOperator
            ? orOperator
            : tag
        )
      );
    },
    []
  );
  const onValuesChange = (changedValue: { key: string }) => {
    const key = Object.keys(changedValue)[0];
    let value = Object.values(changedValue)[0];
    if (!value.includes(',')) return;
    let confirmInputFn;
    let setInputValFn;
    switch (key) {
      case 'Company':
        setInputValFn = setCompanyInputTagValue;
        confirmInputFn = handleCompanyTagInputConfirm;
        break;
      case 'Skills':
        setInputValFn = setSkillInputTagValue;
        confirmInputFn = handleSkillTagInputConfirm;
        break;
      case 'Role':
        setInputValFn = setRoleInputTagValue;
        confirmInputFn = handleRoleTagInputConfirm;
        break;
      case 'Additional Keywords to Exclude':
        setInputValFn = setExcludeInputTagValue;
        confirmInputFn = handleExcludeTagInputConfirm;
        break;
      case 'Additional Keywords to Include':
        setInputValFn = setIncludeInputTagValue;
        confirmInputFn = handleIncludeTagInputConfirm;
        break;
      default:
        break;
    }
    if (setInputValFn) setInputValFn(value.slice(0, -1));
    if (confirmInputFn) confirmInputFn();
  };

  const inputItem = ({
    name,
    tagProperty,
    setTagProperty,
    onTagChange,
    onTagConfirm,
    tagValue,
    hint,
    tooltip,
  }: {
    name: string;
    tagProperty: Array<any>;
    setTagProperty: Function;
    onTagChange: React.ChangeEventHandler;
    onTagConfirm: React.KeyboardEventHandler;
    tagValue: string;
    hint: string;
    tooltip: React.ReactNode;
  }) => (
    <Form.Item
      name={name}
      className={styles.input_form}
      label={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>{name}</div>
          {name === 'Role' ? (
            <Checkbox
              checked={exactSearchRole}
              className={styles.exact_checkbox}
              onChange={onExactSearchChange}
            >
              <Tooltip
                placement="topLeft"
                title="This will only search for profiles that are hiring for the EXACT role you put in. It will significantly limit the amount of results but is very useful in specific use-cases"
              >
                Exact Search
              </Tooltip>
            </Checkbox>
          ) : null}
          <div>
            <Tooltip placement="bottomRight" title={tooltip}>
              <button
                style={{ border: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <HelpIcon />
              </button>
            </Tooltip>
          </div>
        </div>
      }
    >
      <div
        className={clsx(
          styles.container,
          'ant-input',
          activeInput === name ? 'ant-input-focused' : ''
        )}
      >
        {tagProperty.map((tag: string, index: number) =>
          tag !== orOperator && tag !== andOperator ? (
            <div className={styles.tag}>
              {tag}
              <button
                className={styles.closeicon}
                onClick={(e) => deleteTags(index, e, setTagProperty)}
              >
                <CloseIcon
                  width={16}
                  fill={'white'}
                  className="btn-animation"
                />
              </button>
            </div>
          ) : (
            <div
              className={clsx(
                tag === 'OR' ? styles.orTag : styles.andTag,
                styles.tag
              )}
              onClick={(e) =>
                tag === orOperator || tag === andOperator
                  ? switchTag(index, e, setTagProperty)
                  : null
              }
            >
              {tag}
            </div>
          )
        )}
        <div className={styles.inputWrapper}>
          <Input
            type="text"
            size="large"
            placeholder={hint}
            onFocusCapture={() => {
              setActiveInput(name);
              if (isMobile() && !intialMessageDelivered) {
                message.info({
                  content:
                    'Enter comma "," to enter multiple values, tap on operator to change it',
                  duration: 7,
                });
                setIntialMessageDelivered(true);
              }
            }}
            onBlur={(e: any) => {
              setActiveInput('');
              onTagConfirm(e);
            }}
            className={styles.tagInput}
            value={tagValue}
            onKeyDown={(e) =>
              removeTagOnEmptyBackspace(
                e,
                tagProperty,
                setTagProperty,
                onTagConfirm
              )
            }
            onChange={onTagChange}
            onPressEnter={onTagConfirm}
          />
        </div>
      </div>
    </Form.Item>
  );

  return (
    <>
      <Form
        form={form}
        onFinish={isLoggedIn ? onFinish : signInWithGoogleCall}
        onValuesChange={onValuesChange}
        layout={'vertical'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Row justify="space-around" className={styles.inputs} gutter={32}>
          <Col xs={22} md={12}>
            {inputItem({
              name: 'Company',
              tagProperty: companyTags,
              setTagProperty: setCompanyTags,
              onTagChange: handleCompanyTagInputChange,
              onTagConfirm: handleCompanyTagInputConfirm,
              tagValue: companyInputTagValue,
              hint: "A company or list of companies you'd want to work for Ex. Google",
              tooltip:
                'Enter the name of company or companies you are looking to get hired at. You can enter more than one company by using a comma to separate.',
            })}
          </Col>
          <Col xs={22} md={12}>
            {inputItem({
              name: 'Skills',
              tagProperty: skillTags,
              setTagProperty: setSkillTags,
              onTagChange: handleSkillTagInputChange,
              onTagConfirm: handleSkillTagInputConfirm,
              tagValue: skillInputTagValue,
              hint: 'Skills you have. Eg. Tableau, Nodejs',
              tooltip: 'Skills you have. Eg. Tableau, Nodejs',
            })}
          </Col>
        </Row>
        <Row justify="space-around" className={styles.inputs} gutter={32}>
          <Col xs={22} md={12}>
            <Form.Item
              name="location"
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <div>Location</div>
                  <div onClick={(e: any) => e.stopPropagation()}>
                    <Tooltip
                      placement="bottomRight"
                      title={'Select the country where you want the job.'}
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <HelpIcon />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              }
            >
              <CountriesDropdown
                selectedCountry={country}
                setSelectedCountry={setCountry}
              />
            </Form.Item>
          </Col>
          <Col xs={22} md={12}>
            {inputItem({
              name: 'Role',
              tagProperty: roleTags,
              setTagProperty: setRoleTags,
              onTagChange: handleRoleTagInputChange,
              onTagConfirm: handleRoleTagInputConfirm,
              tagValue: roleInputTagValue,
              hint: 'Role you are looking for. Eg. Data scientist, Software Engineer',
              tooltip:
                'Role you are looking for. Eg. Data scientist, Software Engineer',
            })}
          </Col>
        </Row>
        <Row justify="space-around" className={styles.inputs} gutter={32}>
          <Col xs={22} md={12}>
            {inputItem({
              name: 'Additional Keywords to Include',
              tagProperty: includeTags,
              setTagProperty: setIncludeTags,
              onTagChange: handleIncludeTagInputChange,
              onTagConfirm: handleIncludeTagInputConfirm,
              tagValue: includeInputTagValue,
              hint: 'Any additional keyword you need.',
              tooltip: 'Any additional keyword you need.',
            })}
          </Col>
          <Col xs={22} md={12}>
            {inputItem({
              name: 'Additional Keywords to Exclude',
              tagProperty: excludeTags,
              setTagProperty: setExcludeTags,
              onTagChange: handleExcludeTagInputChange,
              onTagConfirm: handleExcludeTagInputConfirm,
              tagValue: excludeInputTagValue,
              hint: 'Any word to exclude.',
              tooltip: 'Any word to exclude.',
            })}
          </Col>
        </Row>
        <Form.Item>
          <Row
            justify="space-around"
            gutter={32}
            style={{ marginBottom: '1rem', marginTop: '0.5rem' }}
          >
            <Tooltip
              title={
                !user
                  ? 'Click login button on top navigation to be able to save queries'
                  : ''
              }
            >
              <Col md={8} xs={22} style={{ paddingBottom: '16px' }}>
                <Button
                  className={clsx(styles.button)}
                  disabled={savedQueryLoading}
                  onClick={handleSaveQuery}
                  size={'large'}
                >
                  Save Query
                </Button>
              </Col>
            </Tooltip>
            <Col md={16} xs={22}>
              <Button
                type="primary"
                disabled={checkQueryParamsEmpty()}
                className={clsx(styles.button)}
                size={'large'}
                htmlType="submit"
              >
                Find out who is hiring!
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <ShareQuery
        shareQuery={{
          id: queryName,
          likes: 0,
          searchCount: 1,
        }}
        setShareQueryName={setQueryName}
        showModal={showShareModal}
        setShowModal={setShowShareModal}
      />

      <Modal
        title={
          window.location.pathname !== '/query' ? 'Save Query' : sharedQuery?.id
        }
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          setQueryName('');
        }}
        style={{ textAlign: 'center' }}
        footer={null}
      >
        {window.location.pathname !== '/query' ? (
          !!user ? (
            <>
              Did you find the results you were looking for? Save your query for
              later to <b>share</b> it with your network and be able to{' '}
              <b>track usage</b> in realtime!
              <div style={{ paddingBottom: '16px' }} className="mt-16">
                <Input
                  placeholder="Enter Query Name"
                  value={queryName}
                  maxLength={100}
                  onChange={(e) =>
                    setQueryName(e.target.value.replace(' ', '-'))
                  }
                />
              </div>
              <Button
                disabled={queryName === '' || savedQueryLoading}
                onClick={saveQuery}
                type="primary"
                style={{ width: '100%' }}
              >
                Save
              </Button>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '1rem 0',
                }}
              >
                <b>OR</b>
              </div>
              <Button
                style={{ marginLeft: '0.5rem' }}
                onClick={() => {
                  ReactGA.event({
                    category: 'Feedback',
                    action: 'Did not Like The Query Button',
                  });
                  setDidNotLikeQueryModal(true);
                  setShowModal(false);
                }}
              >
                See Tips!
              </Button>
            </>
          ) : (
            <>
              <div>
                Did you find the results you were looking for? Save your query
                for later to <b>share</b> it with your network and be able to{' '}
                <b>track usage</b> in realtime!
              </div>
              <Button
                style={{ marginTop: '1rem', width: '100%' }}
                onClick={handleSaveQuery}
                type="primary"
              >
                Save Query
              </Button>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '1rem 0',
                }}
              >
                <b>OR</b>
              </div>
              <Button
                style={{}}
                onClick={() => {
                  ReactGA.event({
                    category: 'Feedback',
                    action: 'Did not Like The Query Button',
                  });
                  setDidNotLikeQueryModal(true);
                  setShowModal(false);
                }}
              >
                See Tips!
              </Button>
            </>
          )
        ) : (
          <>
            Did you like this Query? Then click{' '}
            <span>
              <Like query={sharedQuery} likeStatus={false} />
            </span>
            <div>Total Likes: {sharedQuery?.likes}</div>
            <div>Total Searches: {sharedQuery?.searchCount}</div>
            <div className={styles.feedback_containter}>
              <div>Were the results relevant for you?</div>
              <Radio.Group
                onChange={onFeedbackFlagChange}
                disabled={!isLoggedIn}
              >
                <Space direction="vertical">
                  <Radio value={'USEFUL'}>Yes </Radio>
                  <Radio value={'NOTUSEFUL'}>No</Radio>
                </Space>
              </Radio.Group>
              <div>
                Please give your Feedback :
                <Input
                  disabled={!isLoggedIn}
                  onChange={(e: any) => setQueryFeedback(e.target.value)}
                />
              </div>
              <div>
                <Button
                  className={styles.query_submit}
                  disabled={!queryFeedbackFlag || !isLoggedIn}
                  onClick={feedbackApiCall}
                >
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal>
      <Modal
        visible={didNotLikeQueryModal}
        onCancel={() => {
          setDidNotLikeQueryModal(false);
        }}
        footer={null}
      >
        Here are some <b>tips</b> to improve your search results:-
        <ol style={{ marginLeft: '2rem' }} type="a">
          <li>
            <b>"Exact Search"</b> on job role can give you better results but in
            case on non-traditional roles, it can <b>limit the results </b>.
            Uncheck the exact seach box if you are not sure about the exact
            role.
          </li>
          <li>
            Don't over cook the query. If you add <b>too much parameters</b> and
            values, the query might not be efficient. You can also leave some
            parameters blank or unselected to expand your search results.
          </li>
          <li>
            Use <b>"AND" operator</b> carefully. If you are using "AND"
            operator, it might be one of the reasons for getting lesser results.
          </li>
        </ol>
        Here are some <b>sample queries</b> you can try:-
        <ol style={{ marginLeft: '2rem' }}>
          {sampleQueries.map((query) => (
            <li>
              <a href={query.url}>{query.name}</a>
            </li>
          ))}
        </ol>
      </Modal>
    </>
  );
};

export default InputForm;
