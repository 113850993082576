// import Select from "rc-select";
import { Select } from 'antd';
import React, { FC } from 'react';
import { COUNTRIES } from '../../constants/countries';
import styles from './CountriesDropdown.module.css';

interface CountriesDropdownProps {
  setSelectedCountry: any;
  selectedCountry: string | undefined;
}

const CountriesDropdown: FC<CountriesDropdownProps> = ({
  selectedCountry,
  setSelectedCountry,
}: CountriesDropdownProps) => {
  const [countries, setCountries] = React.useState<
    Array<{
      text: string;
      value: string;
      image?: any;
    }>
  >(COUNTRIES);
  const [imagesAdded, setImagesAdded] = React.useState(false);
  React.useEffect(() => {
    if (imagesAdded) return;
    const countriesWithImage = [...countries];
    for (var i = 0; i < countries.length; i++) {
      const countryVal =
        countriesWithImage[i].value.toLowerCase() === 'www'
          ? 'us'
          : countriesWithImage[i].value.toLowerCase() === 'uk'
          ? 'gb'
          : countriesWithImage[i].value.toLowerCase();

      countriesWithImage[i].image =
        'https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/' +
        countryVal +
        '.svg';
    }
    setCountries(countriesWithImage);
    setImagesAdded(true);
  }, [countries, imagesAdded]);

  return (
    <Select
      size="large"
      showSearch
      placeholder="Select a Country"
      optionFilterProp="title"
      defaultValue={'All Countries'}
      value={selectedCountry}
      onChange={(value) => setSelectedCountry(value)}
      filterOption={(input, option) => {
        return (option!.title as unknown as string)
          .toLowerCase()
          .includes(input?.toLowerCase());
      }}
    >
      {countries.map(({ text, image, value }) => (
        <Select.Option title={text} key={value} value={value}>
          <img src={image} width={20} className={styles.image} alt="country" />
          {text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountriesDropdown;
