/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { FC, useCallback, useContext } from 'react';
import { AppContext, InitialState } from '../../auth';
import HeartIcon from '../../images/heartSvg';
import { likeApiPath } from '../../utils/apiPaths';
import './Like.scss';
import ReactGA from 'react-ga4';
import { Tooltip } from 'antd';

interface LikeProps {
  query: any;
  likeStatus: boolean;
}

const Like: FC<LikeProps> = ({ query, likeStatus }) => {
  const [liked, setLiked] = React.useState(likeStatus);
  const { signInWithGoogleCall } = useContext(AppContext) as InitialState;
  const handleLike = async (likeStatus: boolean) => {
    ReactGA.event(
      { category: 'Query', action: 'Save Query' },
      likeStatus ? 'UNLIKE' : 'LIKE'
    );
    await signInWithGoogleCall();
    axios.post(likeApiPath, {
      id: query.id,
      action: likeStatus ? 'UNLIKE' : 'LIKE',
    });
  };
  const debounce = useCallback((func: any) => {
    let timer: any;
    return function (...args: any) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 2000);
    };
  }, []);
  const debouncedLike = useCallback(
    debounce((liked: boolean) => handleLike(liked)),
    []
  );

  const likeTooltipText = <span>Click here to like</span>;

  return !liked ? (
    <Tooltip placement="top" title={likeTooltipText}>
      <HeartIcon
        style={{ position: 'absolute' }}
        onClick={() => {
          debouncedLike(liked);
          setLiked(!liked);
        }}
        height={22}
        fill="rgba(0, 146, 0, 0.7)"
      />
    </Tooltip>
  ) : (
    <button
      onClick={() => {
        debouncedLike(liked);
        setLiked(!liked);
      }}
      className={'like-button' + (liked ? ' liked' : '')}
    ></button>
  );
};

export default Like;
