import { message } from 'antd';
import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { userDetailApiPath, userRoleApiPath } from '../utils/apiPaths';
import { auth, signInWithGoogle } from '../utils/firebase/firebaseIndex';
import ReactGA from 'react-ga4';

export type InitialState = {
  signInWithGoogleCall: () => void;
  isLoggedIn: boolean;
  userInfo: any;
  authToken: string | null;
};
export const AppContext = createContext<InitialState | null>(null);

interface Props {
  children: any;
}

function UserProvider({ children }: Props) {
  const [user] = useAuthState(auth);

  const [isLoggedIn, setisLoggedIn] = useState(!!user);
  const [authToken, setAuthToken] = useState(null);
  const [userInfo, setUserInfo] = useState({
    username: '',
    profilePic: '',
  });

  useEffect(() => {
    if (isLoggedIn) message.success('Logged In Successfully');
  }, [isLoggedIn]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const userLoggedIn = !!user;
    if (userLoggedIn) {
      try {
        axios.get(userRoleApiPath).then((userRoleGetResponse) => {
          setUserInfo((userInfo) => {
            return {
              ...userInfo,
              username: `${
                userRoleGetResponse.data && userRoleGetResponse.data.fname
              } ${userRoleGetResponse.data && userRoleGetResponse.data.lname}`,
            };
          });
        });

        axios.get(userDetailApiPath).then((userDetailGetResponse) => {
          setUserInfo((userInfo: any) => {
            return {
              ...userInfo,
              profilePic:
                userDetailGetResponse.data &&
                userDetailGetResponse.data[0] &&
                userDetailGetResponse.data[0].profilePhoto,
            };
          });
          setisLoggedIn(true);
        });

        setisLoggedIn(true);
      } catch (err) {
        console.log('err :>> ', err);
      }
    } else {
      setisLoggedIn(false);
    }
  }, [user]);

  const signup = async (authResponse: any) => {
    const { displayName } = authResponse.user;
    const numberOfWords = displayName.split(' ').length;
    const firstname = displayName
      ? displayName.split(' ').slice(0, -1).join(' ')
      : '';
    const lastname =
      numberOfWords > 1 && displayName
        ? displayName.split(' ').slice(-1)[0]
        : '';

    const userRoleRequestBody = {
      role: 'MENTEE',
      fname: firstname, // get values from usestate object
      lname: lastname,
      mentorStatus: '',
      profilePhoto: authResponse.user.photoURL || '',
      linkedin: '',
      mentorRating: 0,
    };

    await axios.post(userRoleApiPath, userRoleRequestBody);

    localStorage.setItem('token', authResponse._tokenResponse.idToken);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const signInWithGoogleCall = async () => {
    if (isLoggedIn || user === undefined) return;

    const authResponse: any = await signInWithGoogle();

    const { isNewUser, idToken } = authResponse._tokenResponse;
    setAuthToken(idToken);
    if (isNewUser) {
      ReactGA.event({
        category: 'SignIn',
        action: 'New User Sign In',
      });
      await signup(authResponse);
    }
    ReactGA.event({
      category: 'SignIn',
      action: 'Existing User Sign In',
    });
    axios
      .get(userRoleApiPath)
      .then((userRoleGetResponse) => {
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            username: `${
              userRoleGetResponse.data && userRoleGetResponse.data.fname
            } ${userRoleGetResponse.data && userRoleGetResponse.data.lname}`,
          };
        });
      })
      .catch(async (error: any) => {
        if (error.response.data === 'User does not exist') {
          try {
            await signup(authResponse);
            localStorage.setItem('token', (await user?.getIdToken()) || '');
          } catch (err) {
            console.log(err);
          }
        }
      });

    axios.get(userDetailApiPath).then((userDetailGetResponse) => {
      setUserInfo((userInfo: any) => {
        return {
          ...userInfo,
          profilePic:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].profilePhoto,
        };
      });
    });

    setisLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('token', authResponse._tokenResponse.idToken);
  };

  return (
    <AppContext.Provider
      value={{ signInWithGoogleCall, isLoggedIn, userInfo, authToken }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default UserProvider;
