import styles from './RedirectPage.module.css';
import { Col, message, Row } from 'antd';
import unsupported from '../../images/download.png';
import firefox from '../../images/firefox.png';
import safari from '../../images/safari.png';
import google from '../../images/google.png';
import 'antd/dist/antd.min.css';
const RedirectPage = () => {
  const onCopy = () => {
    message.success('link copied to clipboard');
    navigator.clipboard.writeText(`${window.location.host}`);
  };
  return (
    <div className={styles.container}>
      <img src={unsupported} height={40} alt="404" />
      <h2 className={styles.title}>You are on an unsupported browser</h2>
      <div className={styles.guide}>
        <h4>Follow the steps below to open the Hiring Search app..</h4>
        <div className={styles.steps}>
          <div className={styles.step_content}>
            <span>
              <b>Step 1:</b> Copy this link{' '}
              <div className={styles.link}>
                <input
                  className={styles.input}
                  disabled
                  value={`${window.location.host}`}
                />
                <button className={styles.btn} onClick={() => onCopy()}>
                  Copy Link
                </button>
              </div>
            </span>
          </div>

          <div className={styles.step_content}>
            <b>Step 2:</b> Open the link in a supported browser such as Chrome,
            Safari, or Firefox to search for Hiring Managers and Recruiters at
            your dream companies!
          </div>
        </div>
        <Row
          className={styles.browser}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          justify="center"
        >
          <Col className="gutter-row" span={6}>
            <img src={google} height={60} alt="404" />
          </Col>
          <Col className="gutter-row" span={6}>
            <img src={safari} height={60} alt="404" />
          </Col>
          <Col className="gutter-row" span={6}>
            <img src={firefox} height={60} alt="404" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RedirectPage;
