import axios from 'axios';
import { auth } from '../utils/firebase/firebaseIndex';
import { logout } from '../utils/firebase/firebaseIndex';

axios.interceptors.request.use(
  async (config) => {
    if (auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      return {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        },
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);
