export const sampleQueries = [
  {
    name: 'Nodejs Dev at Amazon',
    url: 'https://hiring-search.faangpath.com/query?name=JS-amazon',
  },
  {
    name: 'Business Development Manager at Ed-Tech companies India',
    url: 'https://hiring-search.faangpath.com/query?name=BDM@Ed-Tech',
  },
  {
    name: 'US Based Startups Remote Job',
    url: 'https://hiring-search.faangpath.com/query?name=US-Based-Startups-Remote-Job',
  },
  {
    name: 'IOS Developer in US',
    url: 'https://hiring-search.faangpath.com/query?name=IOS-Developer-US',
  },
  {
    name: 'Android Developer in India',
    url: 'https://hiring-search.faangpath.com/query?name=India-Android-Developer',
  },
];
