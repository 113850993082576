import { Col, Row } from 'antd';
import axios from 'axios';
import React, { FC, useEffect } from 'react';
import { saveQueryApiPath } from '../../utils/apiPaths';
import HorizontalCard, { COLOR } from '../HorizontalCard/HorizontalCard';
import InputForm from '../InputForm';
import { useAuthState } from 'react-firebase-hooks/auth';
import SavedQueries from '../SavedQueries/SavedQueries';
import SocialMediaShare from '../SocialMediaShare/SocialMediaShare';
import styles from './Main.module.css';
import { auth } from '../../utils/firebase/firebaseIndex';
import Topnav from '../Topnav';

interface MainProps {}
const whyUseThisToolContent = [
  'Easily find professionals who are hiring',
  'Search by company & skills',
  'Find connections out of your network',
  'Get 100s of results',
  'Save searches you want to view later',
];
const Main: FC<MainProps> = () => {
  const [savedQueries, setSavedQueries] = React.useState<Array<any>>([]);
  const [user, loading] = useAuthState(auth);
  const [savedQueryLoading, setSavedQueryLoading] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (!!user) {
      setSavedQueryLoading(true);
      axios
        .get(saveQueryApiPath)
        .then((res: any) => {
          setSavedQueries(res.data);
        })
        .finally(() => setSavedQueryLoading(false));
    } else {
      setSavedQueries([]);
    }
  }, [user, loading]);

  return (
    <>
      <Topnav />
      <Row className={styles.Main}>
        <Col xl={15} xs={20}>
          <InputForm
            setSavedQueries={setSavedQueries}
            savedQueries={savedQueries}
            savedQueryLoading={savedQueryLoading}
          />
        </Col>
        <Col xl={7} xs={20}>
          <Row justify="space-around">
            <Col
              xl={20}
              xs={22}
              style={{ marginBottom: '2.5rem', width: '100%' }}
            >
              <SavedQueries
                savedQueries={savedQueries}
                setSavedQueries={setSavedQueries}
                savedQueryLoading={savedQueryLoading}
              />
            </Col>
            <Col
              xl={20}
              // md={12}
              xs={22}
              style={{ marginBottom: '2.5rem', width: '100%' }}
            >
              <HorizontalCard
                header="Why use this Tool?"
                color={COLOR.orange}
                content={
                  <ul
                    style={{
                      paddingLeft: '1rem',
                      fontSize: '16px',
                    }}
                  >
                    {whyUseThisToolContent.map((point) => (
                      <li
                        style={{
                          paddingBottom: '0.5rem',
                        }}
                        key={point}
                      >
                        {point}
                      </li>
                    ))}
                  </ul>
                }
              />
            </Col>
            <Col
              xl={20}
              // md={12}
              xs={22}
              style={{ marginBottom: '2.5rem', width: '100%' }}
            >
              <SocialMediaShare />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Main;
